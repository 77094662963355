export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      background: 'white',
      position: 'fixed',
      zIndex: 1001,
      padding: ['1rem', '1rem', '2rem 1rem', '', '2rem 1rem', '2rem'],
      '.smallNavMenu': {
        color: 'text'
      }
    },
    '.containerScrolled': {
      background: 'black',
      position: 'fixed',
      border: 'none',
      padding: ['1rem', '1rem', '2rem 1rem', '', '2rem 1rem', '2rem'],
      '.smallNavMenu': {
        color: 'white',
        '.contact': {
          ml: '1.5rem',
          width: 'fit-content',
          a: {
            variant: 'buttons.secondary',
            padding: '1rem 2rem',
            letterSpacing: '0px',
            width: '100%',
            height: '100%',
            display: 'block',
            width: 'fit-content'
          }
        },
        '.navItemDropdownMenu': {
          border: 'none!important',
          backgroundColor: 'black',
          '.navItem': {
            color: 'white'
          }
        }
      }
    },

    '.logoLocationContainer': {
      margin: ['', '', '', '0rem auto 0rem 0rem'],
      //   padding: '0rem',
      position: ['static'],
      '.logo': {
        maxWidth: ['240px', '', '260px', '240px', '', '270px'],
        filter: 'unset',
        img: {
          p: '0rem',
          filter: 'unset'
        }
      },
      '.logoScrolled': {
        p: '0rem',
        m: '0rem',
        img: {
          p: '0rem',
          maxWidth: '240px',
          objectFit: 'contain'
        }
      }
    },
    '.navItemLink svg': {
      display: 'none'
    },

    '.smallNavMenu': {
      transition: 'all ease-in-out 0.8s',
      alignItems: 'center',

      '.navItem': {
        borderRight: '1px solid',
        borderColor: 'rgba(0,0,0,.35)',
        // padding: ['', '', '', '0rem 0.5rem'],
        paddingLeft: ['', '', '', '0.75rem'],
        paddingRight: ['', '', '', '0.75rem'],
        paddingTop: ['', '', '', '0.5rem'],
        paddingBottom: ['', '', '', '0.5rem'],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0!important',
        a: {
          fontSize: '0.85rem',
          letterSpacing: ['0px', '', '', '', '1px', '1.5px'],
          ':hover': {
            color: 'primary'
          }
        }
      },
      '.contact-us': {
        background: 'primary',
        borderRadius: '5px',
        color: 'white',

        border: '1px solid',
        borderColor: 'primary',
        cursor: 'pointer',
        a: {
          width: '100%',
          display: 'block',
          padding: '.5rem 1.25rem'
        },
        ':hover': {
          background: 'transparent',
          color: 'primary'
        }
      },
      '.view-our-work': {
        border: 'none!important'
      },
      '.dropdownOpen': {
        a: {
          color: 'primary'
        }
      },
      '.navItemDropdownMenu': {
        width: 'fit-content',

        backgroundColor: 'white',
        '.navItem': {
          color: 'black',
          border: 'none'
        }
      },
      '.contact': {
        ml: '1.5rem',
        a: {
          variant: 'buttons.primary',
          padding: '1rem 2rem',
          letterSpacing: '0px',
          width: '100%',
          height: '100%',
          display: 'block'
        }
      }
    },
    '.hamburgerOpen > div': {
      color: 'white!important'
    },
    '.hamburger': {
      backgroundColor: 'black',
      border: 'none',
      borderRadius: '5px',
      padding: '0.8rem',
      display: ['', '', '', 'none'],
      mr: '0rem',
      div: {
        backgroundColor: 'white',
        height: '2px'
      }
    },

    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'transparent',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],

      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: '1.5rem',
          letterSpacing: '0px',
          fontWeight: 'bold',

          ':hover': {
            color: 'primary'
          }
        }
      }
    },
    '.navMenuOpen': {
      '::before': {
        content: "'Chapters'",
        padding: '0.5rem',
        mb: '1.5rem',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        opacity: '0.5',
        borderBottom: '1px solid',
        textTransform: 'uppercase'
      },
      color: 'white',
      backgroundColor: 'transparent',
      padding: ['', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          ':hover': {
            color: 'primary'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },

      '.navItemDropdown': {
        display: 'flex',
        flexDirection: 'column'
      },

      '.navItemDropdownMenu': {
        position: 'static',
        background: 'black',
        textShadow: 'unset',
        '.navItem': {
          ml: '1rem'
        }
      }
    },

    '.phoneSocialContainer': {
      mt: '4rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      opacity: '0.5',

      '> li': {
        width: 'fit-content',
        fontWeight: 'bold',

        a: {
          fontSize: '20px'
        },
        '.fa-phone': {
          mr: '1rem'
        },
        '.socialLink': {
          svg: {
            width: '26px',
            height: '26px'
          }
        }
      }
    },
    '.navBlock': {
      width: ['15%', '40%', '50%', '60%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      height: '100vh',
      position: 'fixed'
    },
    '.navBlockOpen': {
      position: 'fixed',
      backdropFilter: 'blur(1000042px)',
      backgroundImage: 'url(https://img.freepik.com/free-vector/soft-blurred-background_1034-273.jpg?w=2000)',
      backgroundColor: '#000000',
      opacity: 0.98,
      backgroundSize: 'cover',
      filter: 'brightness(0.1)'
    },

    '.navMenuLogo, .smallNavHeading': {
      display: 'none'
    }
  },

  footer: {
    background: 'radial-gradient(ellipse at 12% 35%, rgb(8 8 8 / 84%) 0%, rgb(15 29 17) 57%)',
    color: 'white',
    '.logo': {
      //   filter: 'brightness(0) invert(1)'
    },
    '.gonationLogo': {
      margin: '.5rem 0',
      a: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  sideBySide1: {
    flexWrap: 'wrap',
    padding: ['2rem 1rem', '2rem', '10vh 2rem', '10vh 5vw', '15vh 5vw'],
    '.content': {
      padding: ['2rem 1rem', '2rem ', '1rem 1rem 1rem 3rem', '3rem 1.5rem 3rem 3rem', '4rem 2rem 4rem 4rem'],
      alignItems: 'flex-start',
      '> *': {
        mb: '2rem'
      }
    },
    '.title': { variant: 'text.title', fontSize: ['2rem', '2.5rem', '2.5rem'] },
    '.subtitle': { variant: 'text.subtitle', color: 'secondary' },
    '.text': { variant: 'text.text' }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundColor: 'white',
    height: 'auto',
    color: 'text',
    // mt: ['82px', '', '', '116px'],

    marginTop: ['100px', '', '', '116px'],
    // width: '100%',
    video: {
      height: '100%',
      width: '100%'
    },
    '.hero_content_container': {
      height: ['auto', '', '', '100%'],
      justifyContent: 'center',
      alignItems: 'flex-start',
      border: 'none'
    },

    '.section': {
      pt: ['90px', '', '', '150px'],
      maxWidth: '1000px',
      ml: ['', '', '', '5%'],
      mr: 'auto',

      '> div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
        textAlign: 'left'
      }
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['1rem', '2rem', '3rem', '4rem', '4rem'],
      order: '1',
      maxWidth: 600
    },
    '.subtitle': { variant: 'text.subtitle', fontSize: ['1.75rem', '2rem', '2.5rem', '2.75rem', '3rem'], order: '2' },
    '.text': { variant: 'text.text', fontSize: ['1.25rem', '1.25rem', '1.5rem', '1.75rem', '1.75rem'], order: '3' },
    '.linksContainer': { order: '4' }
  },

  homepageShout: {},

  homepageVideo: {
    padding: ['1rem', '2rem', '3rem', '3rem', '4rem'],
    '.videoContainer': {
      borderRadius: ['20px 20px 0px 0px', '', '', '20px 20px'],
      overflow: 'hidden',
      height: 'auto',
      width: '100%'
    },
    '.hero_content_container': {
      textAlign: 'left',
      width: ['100%', '100%', '100%', 'calc(100% - 6rem)', 'calc(100% - 8rem)', 'calc(100% - 12rem)'],
      border: 'none',
      margin: ['0rem', '', '', '1rem 2rem'],
      backgroundColor: ['black', '', '', 'unset']
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['1.5rem', '', '', '2rem'],
      color: 'white',
      textAlign: 'inherit',
      whiteSpace: 'normal'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      fontFamily: 'body',
      fontWeight: '400',
      textAlign: 'inherit',
      whiteSpace: 'normal',
      lineHeight: '1.6',
      textTransform: 'none',
      color: 'white'
    },
    '.text': {
      variant: 'text.text',
      color: 'white',
      fontSize: ['1rem', '1.1rem', '1.2rem'],
      textAlign: 'inherit',
      whiteSpace: 'normal'
    },
    '.gatsby-link': {
      order: '5',
      mt: '0.5rem'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      padding: '1.5rem 3.5rem',
      order: 4
    },
    '.secondaryCtaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageTagline: {
    '.section': {
      width: '100%',
      maxWidth: 'unset',
      textAlign: 'left',
      padding: ['0rem', '', '5vh 5vw'],
      alignItems: 'flex-start'
    },
    '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '2rem'], order: '1' },
    '.subtitle': { variant: 'text.subtitle', fontSize: ['1.75rem', '2rem', '2.5rem', '2.75rem', '3rem'], order: '2' },
    '.text': { variant: 'text.text', fontSize: ['1.25rem', '1.25rem'], order: '3' },
    '.linksContainer': {
      order: '4',
      '.ctaButton': {
        backgroundColor: 'primary',
        borderColor: 'primary',
        ':hover': {
          backgroundColor: '#202020',
          borderColor: '#202020'
        }
      }
    }
  },

  homepageBoxes: {
    '.section': { width: '100%', maxWidth: 'unset', textAlign: 'left', padding: '5vh 5vw' },
    '.title': {
      variant: 'text.title',
      fontSize: ['3rem', '4rem', '5rem', '4.5rem', '5.5rem'],
      order: '1'
      // borderBottom: '2px solid',
      // borderColor: 'primary'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      fontSize: ['1.75rem', '2rem', '2.5rem', '2.75rem', '3rem'],
      order: '2',
      color: 'white',
      opacity: '0.8'
    },
    '.text': {
      variant: 'text.text',
      opacity: '1',
      fontSize: ['1.1rem', '1.1rem', '1.2rem', '1.3rem'],
      order: '3',
      '*': {
        color: 'white'
      }
    },
    '.linksContainer': {
      order: '4'
    },
    '.swiper-slide': {
      height: 'auto!important'
    }
  },

  homepagePortfolio: {
    backgroundColor: 'background',
    '.title': {
      variant: 'text.title',
      fontSize: ['3rem', '3.5rem', '4rem', '4rem', '4.5rem'],
      color: 'text'
    },
    '.text': {
      order: '3',
      mb: '2rem',
      fontSize: ['1.1rem', '1.1rem', '1.2rem', '1.3rem'],
      '*': {
        color: 'white',
        opacity: '1'
      }
    },
    '.linksContainer': {
      order: '4',
      '.ctaButton': {
        backgroundColor: 'primary',
        borderColor: 'primary',
        ':hover': {
          backgroundColor: '#202020',
          borderColor: '#202020'
        }
      }
    }
  },

  homepageOurWork: {
    '.subtitle': {
      mb: '1.5rem'
    }
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  homepageAwards: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    backgroundColor: 'backgroundSecondary',
    '.text': {
      color: 'white',
      textAlign: 'left'
    },
    '.image': {
      maxWidth: '200px',
      objectFit: 'contain',
      p: '1rem'
    },
    '.imageContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: ['flex-start', '', '', 'center'],
      alignItems: 'center'
    }
  },

  homepageTestimonials: {
    padding: ['2rem 2rem', '2rem 2rem', '4rem 2rem', '5vh 5%'],
    maxWidth: 'unset',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1696863266/sites/lights-camera-dj/lights-camera-dj-logo.png)',
    backgroundSize: ['100px', '150px', '200px', '150px'],
    backgroundPosition: ['95% 50%', '', '95% 50%', '90% 50%'],
    '.menuSectionTitle': {
      variant: 'text.title',
      padding: ['0rem', '0rem', '0rem'],
      fontSize: ['2rem', '2.5rem', '3rem', '4.5rem', '4rem'],
      margin: '0rem 0rem 2rem'
    },

    '.menuItemContentContainer': {
      padding: '0rem'
    },

    '.menuSectionDescription': {
      variant: 'text.text',
      fontSize: '1rem'
    },

    '.menuItemContainer': {
      margin: '0rem',
      maxWidth: ['calc(95% - 100px)', 'calc(95% - 150px)', 'calc(95% - 200px)', 'calc(95% - 250px)']
    },

    '.menuItemNameContainer': {
      variant: 'text.subtitle',
      color: 'secondary',
      padding: ['0rem', '0rem', '0rem'],
      margin: '0rem',
      mb: '1rem'
    },

    '.menuItemName': {
      fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.5rem']
    },

    '.menuItemDescription': {
      variant: 'text.text',
      maxHeight: '264px',
      overflowY: 'scroll',
      padding: ['0rem', '0rem', '0rem', '0%'],
      width: '100%',
      mb: '3rem',
      maxWidth: 800,
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    },

    '.slick-slider': {
      '.slick-arrow': {
        position: 'absolute',
        top: 'unset',
        bottom: '-2rem',
        left: '0rem',
        border: 'solid 1px',
        width: '40px',
        height: '40px',
        padding: '0.5rem'
      },
      '.slick-dots': {
        width: 'fit-content',
        right: '0rem'
      },
      '.slick-next': {
        left: '3rem'
      }
    }
  },

  homepageLogoSlider: {
    overflow: 'hidden',
    backgroundColor: '#f7f6f6',
    borderTop: '1px solid lightgrey',
    padding: '1rem',
    '.slick-slide': {
      '> div': {
        height: '100%',
        img: {
          padding: '1rem',
          height: '100px',
          width: '100%',
          objectFit: 'contain'
        }
      }
    }
  },

  // ? ====================
  // ? =====  Lights  =====
  // ? ====================

  lightsHero: {
    variant: 'customVariants.homepageVideo',
    padding: '0rem',
    height: ['auto!important'],
    video: {
      marginTop: ['83px', '', '116px'],
      //   width: '100%'
      //   height: '100%',
      width: '100%',
      //   height: '100%'
      position: 'static!important',
      left: '0px',
      transform: 'none!important'
    },
    'div.videoContainer': {
      borderRadius: '0px',
      width: '100%'
      //   height: 'auto'
    },
    '.linksContainer': {
      '.ctaButton': {
        backgroundColor: 'primary',
        borderColor: 'primary',
        ':hover': {
          backgroundColor: '#202020',
          borderColor: '#202020'
        }
      }
    }
  },

  lights1: {
    '.videoContainer': {
      width: ['', '', '40%', '', '40%', '50%'],
      iframe: {
        height: ['100vw', '', '30vw']
      }
    },

    '.content': {
      width: ['', '', '60%', '', '60%', '50%'],
      order: ['', '', '2'],
      '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '2.75rem', '', '3rem'] },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    }
  },

  lights2: {
    variant: 'customVariants.homepageVideo',
    padding: '0rem',
    'div.videoContainer': {
      borderRadius: '0px'
    }
  },

  lights3: {
    padding: '1rem',
    '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '2.75rem', '', '3rem'] },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },

    '.slick-initialized .slick-arrow': {
      backgroundColor: 'secondary',
      color: 'white',
      opacity: '0.7'
    }
  },

  lights4: {
    variant: 'customVariants.lights3',
    minHeight: '500px',
    padding: '0rem 1rem 0rem',
    '.content': {
      order: ['', '', '2']
    }
  },

  lightsGallery: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.albumTitle, .albumName': {
      display: 'none'
    }
  },

  // ? ====================
  // ? =====  Camera  =====
  // ? ====================

  cameraHero: {
    mt: ['83px', '', '116px', '116px'],
    height: ['', '', '', 'calc(100vh - 116px)'],
    '.title': { variant: 'text.title', fontSize: ['2.25rem', '2.5rem', '2.75rem', '', '3rem'] },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },
    '.slick-initialized .slick-arrow': {
      backgroundColor: 'secondary',
      color: 'white',
      top: '40%',
      width: '35px',
      height: '35px',
      opacity: '0.7'
    }
  },

  camera1: {
    variant: 'customVariants.homepageTagline'
  },

  cameraCardsTitle: {
    width: '100%',
    maxWidth: '1600px',
    mt: '10vh',
    px: ['1.5rem', '2rem', '3rem', '6rem'],
    '.subtitle': {
      color: 'secondary'
    }
  },

  cameraPackages: {
    variant: 'customVariants.homepageOurWork',
    pt: ['0rem', '0rem', '0rem', '0rem'],
    '.contentBoxes': {
      margin: '0rem'
    },
    'div.box': {
      '.frontCard': {
        backgroundColor: 'secondary',
        borderRadius: '10px'
      },

      ul: {
        li: {
          mb: '0.25rem'
        }
      }
    }
  },
  cameraGallery: {},

  // ? ====================
  // ? =====  DJS  =====
  // ? ====================

  djHero: {
    variant: 'customVariants.lightsHero'
  },

  dj1: {
    variant: 'customVariants.homepageTagline'
  },

  djCardsTitle: {
    variant: 'customVariants.cameraCardsTitle',
    mt: '0rem'
  },

  djCollections: {
    p: ['1rem', '1rem 1.5rem', '1.5rem 2.5rem', '2rem 5.5rem'],
    '.contentBoxes': {
      justifyContent: 'flex-start',
      padding: []
    },
    '.box': {
      maxWidth: '900px',
      width: '100%',
      mb: '2rem',
      '.title': {
        variant: 'text.title',
        padding: '0rem 0rem 0rem 1rem',
        borderLeft: '5px solid',
        borderColor: 'primary',
        mb: '1.5rem'
      },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    }
  },

  djVideo: {
    flexDirection: ['row'],
    flexWrap: 'wrap',
    height: 'auto!important',
    px: ['1.5rem', '2rem', '3rem', '6rem'],
    '.imageContainer': {
      width: 'auto',
      maxWidth: '225px',
      px: 0
    },

    '.content': {
      order: -1,
      width: 'auto',
      alignItems: 'flex-start',
      justifyContent: 'center',
      //   px: ['1.5rem', '2rem', '3rem', '6rem'],
      '.title': {
        variant: 'text.title',
        fontSize: ['2.2rem', '2.5rem', '3rem']
      },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    },

    '.videoContainer': {
      width: '100%',
      height: ['60vw', '60vw', '', '600px'],
      iframe: {
        maxWidth: '1100px'
        // px: ['1.5rem', '2rem', '3rem', '6rem']
      }
    }
  },

  djClients: {
    py: '5vh',
    '.textContent': {
      mb: '10vh',
      px: ['1.5rem', '2rem', '3rem', '6rem']
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['2rem', '2rem', '2rem', '3rem', '3rem']
    },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },
    '.image': {
      height: '80px',
      padding: '1rem',
      width: 'auto',
      objectFit: 'contain'
    }
  },

  djPackages: {
    variant: 'customVariants.cameraPackages'
  },
  djDisclaimer: {
    py: '0rem',
    px: ['1.5rem', '2rem', '3rem', '6rem'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    '.title': {
      fontSize: ['1rem', '', '1.1rem']
    },
    '.subtitle': {
      fontSize: ['0.8rem', '', '0.9rem']
    }
  },

  djGallery: {
    variant: 'customVariants.lightsGallery'
    // img: {
    //   gridRowEnd: 'span 4',
    //   gridColumnEnd: 'span 3'
    // }
  },

  // ? =========================
  // ? =====  Photobooth  ======
  // ? =========================

  photoboothHero: {
    variant: 'customVariants.lightsHero',
    '.title': {
      fontSize: ['1rem', '', '', '1.25rem']
    },
    '.subtitle': {
      fontSize: ['1rem', '', '', '.75rem']
    },
    '.hero_content_container': {
      margin: 0,
      padding: 0,
      maxWidth: 600
    }
  },

  photoboothDescription: {
    display: 'flex',
    textAlign: 'left',
    px: ['1.5rem', '2rem', '3rem', '6rem'],

    '.section': {
      margin: '0rem',
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['2.2rem', '2.5rem', '3rem', '3rem', '3rem'],
      textAlign: 'left'
    },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
      ul: {
        columns: '2',
        li: {
          '::before': {
            content: 'none'
          }
        }
      }
    },
    '.linksContainer': {
      mt: '3rem',
      '.ctaButton': {
        variant: 'buttons.primary',
        backgroundColor: 'primary',
        borderColor: 'primary',
        ':hover': {
          backgroundColor: 'black',
          borderColor: 'black'
        }
      }
    }
  },

  photoboothPackagesTitle: {
    variant: 'customVariants.cameraCardsTitle',
    px: ['1.5rem', '2rem', '3rem', '6rem']
  },

  photoboothGallery: {
    variant: 'customVariants.gallery',
    '.albumName': {
      display: 'none'
    }
  },

  // ? =============================
  // ? =====  Cinematography  ======
  // ? =============================

  cinematographyHero: {
    variant: 'customVariants.photoboothHero'
  },

  cinematographyDescription: {
    variant: 'customVariants.homepageTagline'
  },

  cinematographyPackagesTitle: {
    variant: 'customVariants.cameraCardsTitle',
    px: ['1.5rem', '2rem', '3rem', '6rem']
  },

  cinematographyPackages: {
    variant: 'customVariants.cameraPackages'
  },
  cinematographySlider: {
    // variant: 'customVariants.sideBySide1',
    padding: ['10vh 0rem 15vh', '', '15vh 0rem 25vh'],
    '.title': {
      variant: 'text.title',
      fontSize: ['2.2rem', '2.5rem', '3rem']
    },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },
    '> .mainTextContainer': {
      display: 'none'
    },
    backgroundColor: 'backgroundSecondary',
    color: 'white',
    '.slideBox': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.textContent': {
        padding: '2rem',
        maxWidth: '800px'
      }
    },
    iframe: {
      width: ['100%', '', '', '800px'],
      height: ['60vw', '', '', '400px']
    },

    '.slick-dots': {
      bottom: '-35px'
    },
    '.slick-arrow': {
      position: 'absolute',
      border: 'solid 1px',
      width: '25px',
      height: '25px',
      top: '65%',
      padding: '0.3rem',
      color: 'white',
      borderColor: 'white',
      ':hover': {
        borderColor: 'white',
        color: 'primary'
      }
    },

    '.slick-next': {
      right: '0rem'
    }
  },

  // ? ======================
  // ? =====  Add ons  ======
  // ? ======================

  addOnsPagehero: {
    variant: 'customVariants.lightsHero',
    '.hero_content_container': {
      top: 'unset',
      bottom: '0rem',
      transform: 'unset',
      margin: '0rem'
    },
    img: {
      filter: 'brightness(0.6)'
    }
  },
  addOnsDescription: {
    variant: 'customVariants.homepageTagline',
    '.title': {
      variant: 'text.title',
      order: '1'
    },
    '.text': { variant: 'text.text', order: '3', maxWidth: '800px', width: '100%' }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactHero: {
    variant: 'customVariants.lightsHero'
  },

  contactDescription: {
    variant: 'customVariants.homepageTagline',
    '.title': {
      variant: 'text.title',
      mb: '3rem',
      order: '1'
    },
    '.text': { variant: 'text.text', order: '3' }
  },

  contactForm: {
    display: 'none',
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',

    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ["''"],
      backgroundColor: ['rgb(18 1 1 / 100%)', '', 'rgb(18 1 1 / 100%)'],
      width: ['100%', '', '50%'],
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'text.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%',
      marginTop: '4rem'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'secondary',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'light',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  // ? ===================================
  // ? ====  Our Work Indivdual page  ====
  // ? ===================================

  ourWorkGallery: {
    mt: ['83px', '', '116px', '116px'],
    '.albumContainer': {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      '.albumName': {
        variant: 'text.title',
        writingMode: ['unset', '', 'vertical-lr'],
        p: '1rem'
      }
    }
  },

  ourWorkSection: {
    py: '1rem',
    '.box': {
      width: ['100%', '', '50%'],
      padding: ['1rem', '', '0% 5%'],
      '.title': { variant: 'text.title', mb: '2rem' },
      '.subtitle': { variant: 'text.subtitle' },
      '.text': { variant: 'text.text' },
      '.linksContainer': { order: '4' }
    }
  },

  ourWorkVideo: {
    variant: 'customVariants.djVideo',
    'div.content': { py: '0rem' }
  }
}
