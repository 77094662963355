export default {
  heading: 'Open Sans, sans-serif',
  body: 'Lato, sans-serif',
  monospace: 'Open Sans, monospace',
  display: 'Vujahday Script, cursive',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Vujahday Script',
    'Lato:100,200,300,400,500,600,700,800,900'
  ],
  customFamilies: ['Telegraf', 'TTCommons'],
  customUrls: [
    'https://www.gonation.biz/fonts/telegraf/telegraf.css',
    'https://www.gonation.biz/fonts/ttcommons/ttcommons.css'
  ]
}
