export default {
  h1: {
    fontSize: '2rem',
    margin: '0rem 0rem 1rem',
    fontFamily: 'inherit',
    fontWeight: 'bold'
  },
  h2: {
    fontSize: '1.75rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold'
  },
  h3: {
    fontSize: '1.5rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold'
  },
  h4: {
    fontSize: '1.25rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold'
  },
  h5: {
    fontSize: '1rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold'
  },
  h6: {
    fontSize: '0.8rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold'
  },
  p: {
    margin: '0rem'
  },
  a: {
    fontWeight: 'bold'
  },
  ul: {
    margin: '0rem 0rem 1rem',
    'li:last-child': {
      '::before': {
        content: 'none'
      }
    }
  },
  ol: {
    margin: '0rem 0rem 1rem'
  },
  li: {
    '::before': {
      content: "''",
      height: '140%',
      width: '1px',
      backgroundColor: 'secondary',
      display: 'flex',
      position: 'absolute',
      left: '-13.5px',
      top: '20px'
    },
    '::marker': {
      fontSize: '1em',
      color: 'secondary',
      position: 'relative',
      lineHeight: '1'
    },
    position: 'relative',
    fontSize: '1.25rem',
    lineHeight: '1.5'
  }
}
