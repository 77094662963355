export default {
  container: {},
  primary: {
    backgroundColor: 'black',
    borderRadius: '5px',
    color: 'white',
    border: 'solid 3px',
    borderColor: 'black',
    textTransform: 'uppercase',
    fontSize: ['0.9rem', '', '1.1rem'],
    fontFamily: 'heading',
    transition: 'all ease-in-out 0.5s',
    padding: ['1rem 1.5rem', '1.25rem 2rem'],
    fontWeight: 'bold',
    ':hover': {
      backgroundColor: 'secondary',
      color: 'white',
      borderColor: 'secondary'
    }
  },
  secondary: {
    backgroundColor: 'secondary',
    color: 'light',
    border: 'solid 3px',
    borderColor: 'secondary',
    borderRadius: '5px',
    textTransform: 'uppercase',
    fontSize: ['0.9rem', '', '1.1rem'],
    fontFamily: 'heading',
    transition: 'all ease-in-out 0.5s',
    padding: ['1rem 1.5rem', '1.25rem 2rem'],
    fontWeight: 'bold',
    ':hover': {
      backgroundColor: 'white',
      color: 'black',
      borderColor: 'white'
    }
  }
}
