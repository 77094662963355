export default {
  text: 'black',
  text2: 'grey',
  primary: '#e61e25',
  secondary: '#e61e25',
  background: 'white',
  backgroundSecondary: '#121b21',
  light: '#FFF',
  dark: '#141416'
}
